
  /*
*
* ==========================================
* CUSTOM UTIL CLASSES
* ==========================================
*
*/

/* DEMO GENERAL ============================== */
.work-item {
    padding-left: 0;
    padding-right: 0;
    cursor: pointer;
}
.work-item:hover {
  cursor: pointer;
}


.hover {
    overflow: hidden;
    position: relative;
    padding-bottom: 60%;
  }
  
  .hover-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 90;
    transition: all 0.4s;
  }
  
  .hover img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.3s;
  }
  
  .hover-content {
    position: relative;
    z-index: 99;
  }
  
  
  /* DEMO 1 ============================== */
  .hover-1 img {
    width: 105%;
    position: absolute;
    top: 0;
    left: -5%;
    transition: all 0.3s;
  }
  
  .hover-1-content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
    transition: all 0.4s;
  }
  
  .hover-1 .hover-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  
  .hover-1-description {
    transform: translateY(0.5rem);
    transition: all 0.4s;
    opacity: 0;
  }
  
  .hover-1:hover .hover-1-content {
    bottom: 2rem;
  }
  
  .hover-1:hover .hover-1-description {
    opacity: 1;
    transform: none;
  }
  
  .hover-1:hover img {
    left: 0;
  }
  
  .hover-1:hover .hover-overlay {
    opacity: 0;
  }
  
  