.navbar {
    border-bottom: 1px solid var(--color-primary);
    padding: 0 !important;
}

.navbar-light .navbar-nav .nav-link {
        font-family: var(--font-family);
        font-size: 0.75rem;
        letter-spacing: 0.05rem;
        text-transform: uppercase;    
        text-decoration: none;
        color: #767676;
}
.navbar-nav .nav-link:active {
    color: black;
}

.dropdown-menu {
    font-family: var(--font-family);
    font-size: var(--font-size--nav) !important;
    color: var(--color-text-primary);
    font-weight: 400;
        letter-spacing: 0.05rem;
        text-transform: uppercase;    
        text-decoration: none;
}
.navbar-light .navbar-toggler {
    border: none;
}

.navbar-light .navbar-brand {
    color: var(--color-primary);
    font-weight: 600;
    font-size: 1.5rem;
}