.accordion-item {
    border: none;
}
.accordion-button {
    letter-spacing: 0.025rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 1rem 0;
}

.accordion-button:not(.collapsed), .accordion-button:focus {
    color: var(--color-text-primary);
    background: none !important;
    box-shadow: none;
    border: none;
}
