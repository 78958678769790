:root {
  --font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif;


  --color-white: #ffffff;
  --color-primary: hsl(0, 0%, 0%);
  --color-nav: #767676;
  


  --color-bg-danger: #d80000;

}
