.l-footer {
    padding: 2.8125rem 0 0;
    border-top: 1px solid #aaaaac;
    background-color: #aaaaac;
    font-size: .75rem;
}

.l-footer__bottom {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    width: 100%;
    border-top: 1px solid #000;
    }

    .c-footeraccordion {
        width: 100%;
        border: 0;
        border-radius: 0;
        }
        @media (min-width: 64em) {
        .c-footeraccordion {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        }
    }


.l-footer__copyright {
    padding: .9375rem;
}

.l-footer__column {
    width: 100%;
    padding: 1rem 1rem 0;
    border-top: 0;
    border-bottom: 1px solid;
    }

    @media (min-width: 64em) {
   .l-footer__column {
    max-width: 25%;
    padding: 2rem 1rem;
    border-right: 1px solid;
    }
}


.footer__header {
    font-weight: 300;
font-size: .75rem;
text-transform: uppercase;
margin-bottom: 1rem;
}

.footer__list {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.footer__item {
    line-height: 1.5625rem;
    font-size: .75rem;
}

