* {
  scroll-behavior: smooth;
}
body {
  font-family: var(--font-family);
  color: var(--color-primary);
}

hr {
  background-color: var(--color-primary);
  opacity: 1;
}

a {
  color: #000;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;
  }
  a:hover {
    text-decoration: none;
    color:#000;
  }




/* COLORS
========================================================================== */
/**/
.bg--white {
  background-color: var(--color-white);
}
.bg--grey {
  background-color: var(--color-grey);
}
.bg--primary {
  background-color: var(--color-primary);
}
.color--white {
  color: var(--color-white);
}



.bg-primary {
  background-color: var(--color-bg-primary) !important;
}
.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: var(--color-bg-secondary) !important;
}

.bg-light {
  background-color: var(--color-bg-light) !important;
}
.bg-success {
  background-color: var(--color-bg-success) !important;
}
.bg-warning {
  background-color: var(--color-bg-warning) !important;
}
.bg-danger {
  background-color: var(--color-bg-danger) !important;
}



body {
  background-color: var(--color-bg);
  font-family: var(--font-family);
color: var(--color-text-primary);
}


/* TEXT
========================================================================== */
/**/
p {
font-family: var(--font-family--serif);
 font-size: 0.875rem;
 letter-spacing: 0.1rem;
 line-height: 1.5;
}
h1 {
  font-family: var(--font-family);
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
line-height: 1.2;
text-transform: uppercase;
}
h2 {
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 0.025rem;
}
h3 {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.025rem;
}
h4 {
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 0.025rem;
}

/* BUTTONS
========================================================================== */
/**/
.btn, .btn:hover {
 text-transform: uppercase;
 letter-spacing: .0575rem;
  border-radius: 100vh;
  color: #000;
border: 1px solid #000;
background: #fff;
font-family: var(--font-family);
font-weight: 600;
font-size: .8125rem;
padding: 0 1.25rem;
min-height: 2rem;
}




.l-site__wrapper {
  position: relative;
  overflow-y: auto;
  }


.c-homecarousel {
-ms-flex-wrap: wrap;
flex-wrap: wrap;
margin: 0 auto;
position: relative;
overflow: hidden;
list-style: none;
padding: 0;
z-index: 1;
}

ul.c-homecarousel__wrapper {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  transform: translateZ(0);
  position: relative;
width: 100%;
height: 100%;
z-index: 1;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-sizing: content-box;
box-sizing: content-box;
list-style-type: none;
margin: 0;
padding: 0;
  }
  



/* HEADER
========================================================================== */
/**/
.header-overlay {
  background-color: #ffffff;
  background-image: linear-gradient(-45deg, #ffffff, #ffd6e6, #bcedff, #bcffef);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
	padding-top:10rem;
  padding-bottom:10rem;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}


/* SPACING
========================================================================== */
/**/



/* PORTFOLIO
========================================================================== */
/**/
.nav-link {
  color: var(--color-nav);
  font-size: .75rem;
  text-transform: capitalize;
}
.nav-link.active {
  color: var(--color-nav);
}

.nav-tabs {
  border-bottom: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link:hover  {
  border: none;
  outline: none;
}


.nav-tabs .nav-link:hover {
  text-decoration: underline;
}