.c-homecarousel__slide {
    height: 100%;
    overflow: hidden;
    -ms-flex-negative: 0;
flex-shrink: 0;
width: 100%;
height: 100%;
position: relative;
display: list-item;
list-style-type: none;
    }

  .ca-homecategory__image {
    display: block;
        position: relative;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 15.625rem;
        width: 100%;
        aspect-ratio: 2 / 1;
        }



    .ca-homecategory__imagewrapper--desktop {
        display: none;
        }

    .ca-homecategory__imagewrapper--tablet {
        display: none;
        }


        .ca-homecategory__title {
            position: absolute;
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
            top: 50%;
            font-size: .75rem;
            text-transform: uppercase;
            text-align: center;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            color: #000;
            }


        .ca-homecategory__title--positionbottom {
            top: auto;
            bottom: 5%;
            -webkit-transform: translateX(-50%) translateY(0);
            transform: translateX(-50%) translateY(0);
            }




        .c-button {
            line-height: 2rem;
            font-weight: 600;
            position: relative;
            display: flex;
            -webkit-box-align: center;
            align-items: center;
            -webkit-box-pack: center;
            justify-content: center;
            box-sizing: border-box;
            padding: 0 1.25rem;
            cursor: pointer;
            -webkit-user-select: none;
text-align: center;
vertical-align: middle;
white-space: normal;
text-decoration: none;
color: #000;
border: 1px solid #000;
border-radius: 100vh;
background: #fff;
-webkit-appearance: none;
letter-spacing: .0575rem;
font-size: .8125rem;
        }

        .c-button:active, .c-button:hover {
            text-decoration: none;
        }

        @media (min-width: 64em) {

            .ca-homecategory__image {
                width: 100%;
                height: auto;
                }
            .ca-homecategory__title--positionbottom {
                bottom: 2rem;
                }

            .ca-homemain__item {
                display: block;
                }

                .ca-homemain__item .ca-homemain__button {
                    min-width: 8.25rem;
                    margin: 0 .25rem;
                    }
                    .ca-homecategory__imagewrapper--desktop {
                        display: block;
                        }
        }
